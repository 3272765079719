





















































































import {
  Vue, Component, PropSync, Watch,
} from 'vue-property-decorator';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import InvestorProfilePersonalViewModel
  from '@/vue-app/view-models/components/contract-savings/profiling/allianz-investor-profile/investor-profile-personal-view-model';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';

@Component({
  name: 'InvestorProfilePersonal',
  components: {
    CustomAutocomplete,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})

export default class InvestorProfilePersonal extends Vue {
  @PropSync('onboardingStep', { type: Object, required: true })
  private onboarding_step_synced!: OnBoardingStepEntity;

  @PropSync('questions', { type: Array, required: true, default: () => [] })
  private questions_synced!: Array<TemplateQuestionnaireEntity>;

  @PropSync('loadFinished', { type: Boolean, required: true, default: false })
  private load_finished_synced!: boolean;

  investor_profile_personal_view_model = Vue.observable(
    new InvestorProfilePersonalViewModel(this),
  );

  @Watch('load_finished_synced')
  onLoadFinishedChange(load_finished_synced: boolean) {
    this.investor_profile_personal_view_model.chargeOnLoadFinished(
      load_finished_synced, this.questions_synced, this.onboarding_step_synced,
    );
  }

  mounted() {
    if (this.load_finished_synced) {
      this.investor_profile_personal_view_model.chargeOnLoadFinished(
        this.load_finished_synced, this.questions_synced, this.onboarding_step_synced,
      );
    }
  }

  async created() {
    await this.investor_profile_personal_view_model.initialize();
  }
}

