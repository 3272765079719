import Vue from 'vue';
import TYPES from '@/types';
import { requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import GetPersonAsyncQuery
  from '@/modules/my-investment/person/application/queries/get-person-async-query';

// Domain
import {
  OptionEntity,
} from '@/modules/my-investment/catalogs/allianz/option/domain/entities/option-entity';
import Translator from '@/modules/shared/domain/i18n/translator';
import {
  QuestionEntity,
} from '@/modules/my-investment/catalogs/allianz/question/domain/entities/question-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class InvestorProfilePersonalViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.GET_PERSON_ASYNC_QUERY)
  private readonly get_person_query_service!: GetPersonAsyncQuery;

  readonly i18n_namespace = 'components.contract-savings.profiling.investment-profile';

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly age_range_index = {
    AGE_RANGE_18_AND_40: 0,
    AGE_RANGE_41_AND_65: 1,
    AGE_RANGE_MORE_THAN_65: 2,
  };

  private readonly view!: Vue;

  is_loading = true;

  date_of_birth = '';

  form_valid = false;

  inputs = {
    age_range: '',
  };

  inputs_rules = {
    age_range: [
      requiredRule,
    ],
    educational_level: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'valor'),
    ],
    investment_horizon: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'valor'),
    ],
  }

  object_format = {
    llave: '',
    valor: '',
    descripcion: '',
  }

  onboarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {
      age_range: this.object_format,
      educational_level: this.object_format,
      investment_horizon: this.object_format,
    },
  };

  education_level_items: Array<OptionEntity> = [];

  age_range_items: Array<OptionEntity> = [];

  investment_horizon_items: Array<OptionEntity> = [];

  public constructor(view: any) {
    this.view = view;
  }

  get can_continue() {
    return this.form_valid && !this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  calculateAge = () => {
    const birthday = new Date(this.date_of_birth);
    const ageDiffMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  fillAgeRangeOption = (age_range_options: Array<OptionEntity>) => {
    const age = this.calculateAge();
    if (age >= 18 && age <= 40) {
      this.onboarding_step.payload.age_range = age_range_options[
        this.age_range_index.AGE_RANGE_18_AND_40];
      this.inputs.age_range = age_range_options[this.age_range_index.AGE_RANGE_18_AND_40]
        .descripcion;
    }
    if (age >= 41 && age <= 65) {
      this.onboarding_step.payload.age_range = age_range_options[
        this.age_range_index.AGE_RANGE_41_AND_65];
      this.inputs.age_range = age_range_options[this.age_range_index.AGE_RANGE_41_AND_65]
        .descripcion;
    }
    if (age > 65) {
      this.onboarding_step.payload.age_range = age_range_options[
        this.age_range_index.AGE_RANGE_MORE_THAN_65];
      this.inputs.age_range = age_range_options[this.age_range_index.AGE_RANGE_MORE_THAN_65]
        .descripcion;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.onboarding_step);
  }

  loadOptions = async (questions: Array<QuestionEntity>) => {
    this.is_loading = true;
    if (questions.length) {
      this.education_level_items = questions[0].opciones;
      this.age_range_items = questions[1].opciones;
      this.investment_horizon_items = questions[2].opciones;
    }
    this.is_loading = false;
  }

  loadPersonInfo = async () => {
    try {
      const person = await this.get_person_query_service.execute();
      this.date_of_birth = person.date_of_birth;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.error_load_person'));
    }
  }

  setInputsDataFromStep = async (onboarding_step: OnBoardingStepEntity) => {
    this.is_loading = true;
    this.onboarding_step = { ...onboarding_step };
    if (!this.onboarding_step.payload.age_range.valor) {
      await this.loadPersonInfo();
      this.fillAgeRangeOption(this.age_range_items);
    }
    if (onboarding_step.payload.age_range.valor) {
      this.inputs.age_range = onboarding_step.payload.age_range?.descripcion;
    }
    this.is_loading = false;
  }

  chargeOnLoadFinished = (
    load_finished: boolean,
    template: Array<TemplateQuestionnaireEntity>,
    onboarding_step: OnBoardingStepEntity,
  ) => {
    if (load_finished) {
      const questionnaire = template[0];
      const question_entities = questionnaire.secciones[0].preguntas;
      this.loadOptions(question_entities);
      this.setInputsDataFromStep(onboarding_step);
    }
  }

  initialize = async () => {
    this.is_loading = false;
  }
}
